const ID = "Id";
//const LABEL_MIN = 'Min'
const LABEL_MAX = "Max";
//const MIN = 'min'
//const MAX = 'max'

// Tensao  Neutro deve ser concatenado a headRowsGestorData
const tensao = "tensao";
const tensaoLabel = "Tensão";
// Corrente deve ser concatenado a headRowsGestorData
const corrente = "corrente";
const correnteLabel = "Corrente";
// Potencia Ativa deve ser concatenado a headRowsGestorData
const pAtiva = "potencia_ativa";
const potencia = "Potência";
const consumo = "Consumo";
const demanda = "Demanda";
const pAtivaLabel = "Potência Ativa";
// Potencia Reativa deve ser concatenado a headRowsGestorData
const pReativa = "potencia_reativa";
const pReativaLabel = "Potência Reativa";
// Potencia Aparente deve ser concatenado a headRowsGestorData
const pAparente = "potencia_aparente";
const pAparenteLabel = "Pontência Aparente";
// Fator Potencia deve ser concatenado a headRowsGestorData
const fPotencia = "fator_potencia";
const fPotenciaLabel = "Fator Potência";
// Frequencia deve ser concatenado a headRowsGestorData
const frequencia = "frequencia";
const frequenciaLabel = "Frequência";
// Energia Consumida deve ser concatenado a headRowsGestorData
const eConsumida = "energia_consumida";
const eConsumidaLabel = "Energia Consumida";

const eConsumidaKwh = "energia_consumida_kwh";
const eConsumidaKwhLabel = "Consumo Kwh";

// Demanda Potencia deve ser concatenado a headRowsGestorData
const dPotencia = "demanda_potencia";
const dPotenciaLabel = "Demanda Potência";

export const allColetaHeadRows = [
  { id: "id", showChart: false, disablePadding: false, label: ID },
  {
    id: "gestor_id",
    showChart: false,
    disablePadding: false,
    label: "Gestor Id",
  },
  {
    id: "data_coleta",
    showChart: false,
    disablePadding: false,
    label: "Data/Hora Coleta",
    type: "date_time",
  },
  {
    id: `${tensao}_l1_neutro`,
    showChart: false,
    disablePadding: false,
    label: `${tensaoLabel} L1 Neutro`,
  },
  {
    id: `${tensao}_l2_neutro`,
    showChart: false,
    disablePadding: false,
    label: `${tensaoLabel} L2 Neutro`,
  },
  {
    id: `${tensao}_l3_neutro`,
    showChart: false,
    disablePadding: false,
    label: `${tensaoLabel} L3 Neutro`,
  },
  {
    id: `${tensao}_l1_l2`,
    showChart: false,
    disablePadding: false,
    label: `${tensaoLabel} L1 L2`,
  },
  {
    id: `${tensao}_l2_l3`,
    showChart: false,
    disablePadding: false,
    label: `${tensaoLabel} L2 L3`,
  },
  {
    id: `${tensao}_l3_l1`,
    showChart: false,
    disablePadding: false,
    label: `${tensaoLabel} L3 L1`,
  },
  {
    id: `${corrente}_i1`,
    showChart: false,
    disablePadding: false,
    label: `${correnteLabel} I1`,
  },
  {
    id: `${corrente}_i2`,
    showChart: false,
    disablePadding: false,
    label: `${correnteLabel} I2`,
  },
  {
    id: `${corrente}_i3`,
    showChart: false,
    disablePadding: false,
    label: `${correnteLabel} I3`,
  },
  {
    id: `${corrente}_n`,
    showChart: false,
    disablePadding: false,
    label: `${correnteLabel} N`,
  },
  //{ id: `${pAtiva}_p1`, showChart: false, disablePadding: false, label: `${pAtivaLabel} P1`},
  //{ id: `${pAtiva}_p2`, showChart: false, disablePadding: false, label: `${pAtivaLabel} P2`},
  //{ id: `${pAtiva}_p3`, showChart: false, disablePadding: false, label: `${pAtivaLabel} P3`},
  {
    id: `${pAtiva}_total`,
    showChart: false,
    disablePadding: false,
    label: `${pAtivaLabel} Total`,
  },
  {
    id: `${pReativa}_total`,
    showChart: false,
    disablePadding: false,
    label: `${pReativaLabel} Total`,
  },
  {
    id: `${pAparente}_total`,
    showChart: false,
    disablePadding: false,
    label: `${pAparenteLabel} Total`,
  },
  //{ id: `${fPotencia}_1`, showChart: false, disablePadding: false, label: `${fPotenciaLabel} 1` },
  //{ id: `${fPotencia}_2`, showChart: false, disablePadding: false, label: `${fPotenciaLabel} 2` },
  //{ id: `${fPotencia}_3`, showChart: false, disablePadding: false, label: `${fPotenciaLabel} 3` },
  {
    id: `${fPotencia}_total`,
    showChart: false,
    disablePadding: false,
    label: `${fPotenciaLabel} Total`,
  },
  {
    id: `${frequencia}`,
    showChart: false,
    disablePadding: false,
    label: `${frequenciaLabel}`,
  },
  {
    id: `${eConsumida}_varh`,
    showChart: false,
    disablePadding: false,
    label: `${eConsumidaLabel} VARh`,
  },
  {
    id: `${eConsumida}_kvarh`,
    showChart: false,
    disablePadding: false,
    label: `${eConsumidaLabel} KVARh`,
  },
  {
    id: `${eConsumida}_mvarh`,
    showChart: false,
    disablePadding: false,
    label: `${eConsumidaLabel} MVARh`,
  },
  {
    id: `${eConsumida}_wh`,
    showChart: false,
    disablePadding: false,
    label: `${eConsumidaLabel}`,
  },
  {
    id: `${eConsumida}_kwh`,
    showChart: false,
    disablePadding: false,
    label: `${eConsumidaLabel} KWh`,
  },
  {
    id: `${eConsumida}_mwh`,
    showChart: false,
    disablePadding: false,
    label: `${eConsumidaLabel} MWh`,
  },
  //{ id: `${dPotencia}_ativa_total`, showChart: false, disablePadding: false, label: `${dPotenciaLabel} Ativa` },
  //{ id: `${dPotencia}_reativa_total`, showChart: false, disablePadding: false, label: `${dPotenciaLabel} Reativa` },
  //{ id: `${dPotencia}_aparente_total`, showChart: false, disablePadding: false, label: `${dPotenciaLabel} Aparente` },
  {
    id: `energia_consumida_kw_acc`,
    showChart: false,
    disablePadding: false,
    label: `Energia KW Acumulada`,
  },
  {
    id: `energia_consumida_kwar_acc`,
    showChart: false,
    disablePadding: false,
    label: `Energia Kvar Acumulada`,
  },
];

export const headRowsGestor = [
  // { id: "id", disablePadding: false, label: "Id. DB" },
  // { id: "id_equipamento", disablePadding: false, label: "Id. do Medidor" },
  // { id: "equipamento", disablePadding: false, label: "Equipamento" },
  { id: "fazenda", disablePadding: false, label: "Fazenda", fontSize: "10pt"},
  // { id: "usuario.nome", disablePadding: false, label: "Cliente" },
  { id: "numeroQuedas", disablePadding: false, label: "Queda de energia"},
  { id: "potencialEconomia", disablePadding: false, label: "Potencial economia"},
  { id: "consumoPonta", disablePadding: false, label: "Horário de ponta"},
  { id: "ufer", disablePadding: false, label: "UFER"},
];

export const headRowsConfigGestor = [
  { id: "id", disablePadding: false, label: "Id. DB" },
  { id: "id_equipamento", disablePadding: false, label: "Id. do Medidor" },
  { id: "equipamento", disablePadding: false, label: "Equipamento" },
  { id: "fazenda", disablePadding: false, label: "Fazenda" },
  { id: "usuario.nome", disablePadding: false, label: "Cliente" },
];

export const headRowsGestorAgua = [
  { id: "id", disablePadding: false, label: "Id. DB" },
  { id: "id_equipamento", disablePadding: false, label: "Id. do Medidor" },
  { id: "fazenda", disablePadding: false, label: "Fazenda" },
  { id: "usuario.nome", disablePadding: false, label: "Cliente" },
];

export const headRowsUsuario = [
  { id: "id", disablePadding: false, label: ID },
  { id: "nome", disablePadding: false, label: "Nome", type: "text" },
  { id: "email", disablePadding: false, label: "Email", type: "email" },
  { id: "status", disablePadding: false, label: "Status", type: "status" },
];

export const headRowsConcessionaria = [
  { id: "id", disablePadding: false, label: "Id. DB"},
  { id: "nome", disablePadding: false, label: "Concessionária"},
  { id: "regiao", disablePadding: false, label: "Região"}
]

export const headRowsAlerts = [

  {
    id: "datahora_coleta",
    showChart: false,
    disablePadding: false,
    label: "Data/Hora Coleta",
    type: "date_time",
  },
  { id: "datahora_envio", disablePadding: false, label: "Horário", type: "date_time" },
  { id: "descricao", disablePadding: false, label: "Tipo de alerta", type: "text" },

];


export const headRowsGestorData = [
  { id: "id", showChart: false, disablePadding: false, label: ID },
  {
    id: "id_equipamento",
    showChart: false,
    disablePadding: false,
    label: "Id Equipamento",
  },
  {
    id: "data_coleta",
    showChart: false,
    disablePadding: false,
    label: "Data/Hora Coleta",
    type: "date_time",
  },
];

export const headRowsGestorDataTensao = [
  {
    id: `${tensao}_l1_neutro`,
    showChart: false,
    disablePadding: false,
    label: `${tensaoLabel} L1 Neutro`,
  },
  {
    id: `${tensao}_l2_neutro`,
    showChart: false,
    disablePadding: false,
    label: `${tensaoLabel} L2 Neutro`,
  },
  {
    id: `${tensao}_l3_neutro`,
    showChart: false,
    disablePadding: false,
    label: `${tensaoLabel} L3 Neutro`,
  },
];

export const headRowsGestorDataTensaoTensao = [
  {
    id: `${tensao}_l1_l2`,
    showChart: false,
    disablePadding: false,
    label: `${tensaoLabel} L1 L2`,
  },
  {
    id: `${tensao}_l2_l3`,
    showChart: false,
    disablePadding: false,
    label: `${tensaoLabel} L2 L3`,
  },
  {
    id: `${tensao}_l3_l1`,
    showChart: false,
    disablePadding: false,
    label: `${tensaoLabel} L3 L1`,
  },
];

export const headRowsGestorDataCorrente = [
  {
    id: `${corrente}_i1`,
    showChart: false,
    disablePadding: false,
    label: `${correnteLabel} I1`,
  },
  {
    id: `${corrente}_i2`,
    showChart: false,
    disablePadding: false,
    label: `${correnteLabel} I2`,
  },
  {
    id: `${corrente}_i3`,
    showChart: false,
    disablePadding: false,
    label: `${correnteLabel} I3`,
  },
  {
    id: `${corrente}_n`,
    showChart: false,
    disablePadding: false,
    label: `${correnteLabel} N`,
  },
];

export const headRowsGestorDataPotencia = [
  {
    id: `${pAtiva}_total`,
    showChart: false,
    disablePadding: false,
    label: `${pAtivaLabel} Total`,
    transformPFR: true
  },

  {
    id: `${pReativa}_total`,
    showChart: false,
    disablePadding: false,
    label: `${pReativaLabel} Total`,
  },
];

export const headRowsGestorDataPotenciaInjetada = [
  {
    id: `${pAtiva}_total`,
    showChart: false,
    disablePadding: false,
    label: `${pAtivaLabel} Total`,
    transformPFR: true
  },

  {
    id: `${pReativa}_total`,
    showChart: false,
    disablePadding: false,
    label: `${pReativaLabel} Total`,
  },

  {
    id: `energia_injetada_kwh`,
    showChart: false,
    disablePadding: false,
    label: `Potencia Injetada`
  }
];

export const headRowsWaterConsumption = [
  {
    id: `consumo`,
    showChart: false,
    disablePadding: false,
    label: `Consumo`,
    transformPFR: false
  },
];

export const headRowsWaterConsumptionD = [
  {
    id: `consumptionSum`,
    showChart: false,
    disablePadding: false,
    label: `Consumo Diário`,
    transformPFR: false
  },
];

export const headRowsGestorDataPotenciaD = [
  {
    id: `${pAtiva}_total`,
    showChart: false,
    disablePadding: false,
    label: `${pAtivaLabel} Total`,
    transformPFR: true
  },
];

export const headRowsGestorDataDemandaAnual = [
  {
    id: "max4",
    showChart: false,
    disablePadding: false,
    label: `Demanda Máxima`,
  }
]

export const headRowsGestorDataConsumoD = [
  {
    id: `${eConsumidaKwh}`,
    showChart: false,
    disablePadding: false,
    label: `${eConsumidaKwhLabel}`,
    transformPFR: true
  },
];

export const headRowsGestorDataConsumoKwh = [
  {
    id: `${eConsumidaKwh}`,
    showChart: false,
    disablePadding: false,
    label: `${eConsumidaKwhLabel}`,
    transformPFR: true
  },
  {
    id: `ufer`,
    showChart: false,
    disablePadding: false,
    label: `Ufer`,
  },
];

export const headRowsGestorDataFatorPotencia = [
  {
    id: `${fPotencia}_total`,
    showChart: false,
    disablePadding: false,
    label: `${fPotenciaLabel} Total`,
    transformPFR: true
  },
];

export const headRowsGestorDataFrequencia = [
  {
    id: `${frequencia}`,
    showChart: false,
    disablePadding: false,
    label: `${frequenciaLabel}`,
  },
];

export const headRowsGestorDataEnergiaConsumida = [
  {
    id: `${eConsumida}_varh`,
    showChart: false,
    disablePadding: false,
    label: `${eConsumidaLabel} VARh`,
  },
  {
    id: `${eConsumida}_kvarh`,
    showChart: false,
    disablePadding: false,
    label: `${eConsumidaLabel} KVARh ${LABEL_MAX}`,
  },
  {
    id: `${eConsumida}_mvarh`,
    showChart: false,
    disablePadding: false,
    label: `${eConsumidaLabel} MVARh`,
  },
  {
    id: `${eConsumida}_wh`,
    showChart: false,
    disablePadding: false,
    label: `${eConsumidaLabel}`,
  },
  {
    id: `${eConsumida}_kwh`,
    showChart: false,
    disablePadding: false,
    label: `${eConsumidaLabel} KWh`,
  },
  {
    id: `${eConsumida}_mwh`,
    showChart: false,
    disablePadding: false,
    label: `${eConsumidaLabel} MWh`,
  },
];

export const headRowsGestorDataDemandaPotencia = [
  {
    id: `${dPotencia}_ativa`,
    showChart: false,
    disablePadding: false,
    label: `${dPotenciaLabel} Ativa`,
  },
  {
    id: `${dPotencia}_reativa`,
    showChart: false,
    disablePadding: false,
    label: `${dPotenciaLabel} Reativa`,
  },
  {
    id: `${dPotencia}_aparente`,
    showChart: false,
    disablePadding: false,
    label: `${dPotenciaLabel} Aparente`,
  },
];

export const selectOptions = [
  /*{
    id: 'tensao_neutro', label: `${tensaoLabel} Neutro`, headRows: [...headRowsGestorDataTensao], open: false,
    referenceLine: ['transf_potencia1'], yLabel: 'Volts'
  },*/
  {
    id: "potencia",
    label: potencia,
    headRows: [...headRowsGestorDataPotencia],
    open: true,
    unique: true,
    yLabel: "kW",
  },
  {
    id: "fator_potencia",
    label: fPotenciaLabel,
    headRows: [...headRowsGestorDataFatorPotencia],
    unique: true,
    open: true,
    yLabel: "Fator Potência",
  },
  {
    id: "consumoKwh",
    label: consumo,
    headRows: [...headRowsGestorDataConsumoKwh],
    unique: true,
    open: true,
    yLabel: "Consumo",
  },
  {
    id: "tensao_tensao",
    label: `${tensaoLabel}`,
    headRows: [...headRowsGestorDataTensaoTensao],
    open: true,
    unique: true,
    referenceLine: ["transf_potencia1"],
    yLabel: "Volts",
  },
  {
    id: "corrente",
    label: correnteLabel,
    headRows: [...headRowsGestorDataCorrente],
    open: true,
    unique: true,
    yLabel: "Ampéres",
  },
  {
    id: "frequencia",
    label: frequenciaLabel,
    headRows: [...headRowsGestorDataFrequencia],
    unique: true,
    open: true,
    yLabel: "Hz",
  },
];

export const selectOptionsABNT = [
  {
    id: "potencia",
    label: potencia,
    headRows: [...headRowsGestorDataPotencia],
    unique: true,
    open: true,
    yLabel: "kW",
  },
  {
    id: "potenciaD",
    label: potencia,
    headRows: [...headRowsGestorDataPotenciaD],
    unique: true,
    open: true,
    yLabel: "kW",
  },
  {
    id: "consumoD",
    label: potencia,
    headRows: [...headRowsGestorDataConsumoD],
    unique: true,
    open: true,
    yLabel: "kWh Diário",
  },
  {
    id: "fator_potencia",
    label: fPotenciaLabel,
    headRows: [...headRowsGestorDataFatorPotencia],
    unique: true,
    open: true,
    yLabel: "Fator Potência",
  },
  {
    id: "consumoKwh",
    label: consumo,
    headRows: [...headRowsGestorDataConsumoKwh],
    unique: true,
    open: true,
    yLabel: "Consumo kWh",
  },
  {
    id: "demandaAnual",
    label: demanda,
    headRows: [...headRowsGestorDataDemandaAnual],
    unique: true,
    open: true,
    yLabel: "kW",
  }
];

export const selectOptionsWater = [
  {
    id: "consumo",
    label: "Consumo",
    headRows: [...headRowsWaterConsumption],
    open: true,
    unique: true,
    yLabel: "m³",
  },
  {
    id: "aguaConsumoD",
    label: "Consumo Diário",
    headRows: [...headRowsWaterConsumptionD],
    open: true,
    unique: true,
    yLabel: "m³",
  },
];

export const hostsABNT = [
  "gprs.absltda.com.br"
];

export const headRowsRelatorio = [
  { id: "data_hora", label: "Data e Hora", type: "date_time" },
  { id: "potencia_ativa", label: "Potência Ativa(KW)", type: "number" },
  {
    id: "potencia_reativa_indutiva",
    label: "Potência Reativa",
    type: "number",
  },
  { id: "energia_consumida", label: "Energia Consumida", type: "number" },
  { id: "energia_reativa", label: "Energia Reativa", type: "number" },
  { id: "posto_horario", label: "Posto Horário (R/FP/P)" },
  { id: "tipo_cobranca", label: "Cobrança Capacitiva(C) Indutiva(L)" },
  { id: "fator_potencia", label: "Fator de Potência" },
  { id: "ufer", label: "UFER", type: "precision2" },
];

// export const headRowsRelatorioAbnt = [
//   { id: "data_hora", label: "Data e Hora", type: "date_time" },
//   { id: "potencia_ativa", label: "Potência Ativa(KW)", type: "number" },
//   {
//     id: "potencia_reativa_indutiva",
//     label: "Potência Reativa",
//     type: "number",
//   },
//   { id: "energia_consumida", label: "Energia Consumida", type: "number" },
//   { id: "energia_reativa", label: "Energia Reativa", type: "number" },
//   { id: "posto_horario", label: "Posto Horário (R/FP/P)" },
//   { id: "tipo_cobranca", label: "Cobrança Capacitiva(C) Indutiva(L)" },
//   { id: "fator_potencia", label: "Fator de Potência" },
//   { id: "ufer", label: "UFER", type: "precision2" },
// ];

export const headRowsAguaDetalhado = [
  {
    id: "id",
    showChart: false,
    disablePadding: false,
    label: "ID",
    type: "number",
  },
  {
    id: "data_coleta",
    showChart: false,
    disablePadding: false,
    label: "Data Hora",
    type: "date_time",
  },
  {
    id: "consumo",
    showChart: false,
    disablePadding: false,
    label: "Volume captado (m³)",
    type: "precision2",
  },
  {
    id: "volumeOutorgado",
    showChart: false,
    disablePadding: false,
    label: "Volume outorgado (m³)",
    type: "number"
  },
  {
    id: "vazao",
    showChart: false,
    disablePadding: false,
    label: "Vazão instantânea (m³/h)",
    type: "precision2"
  },
  {
    id: "vazaoOutorgada",
    showChart: false,
    disablePadding: false,
    label: "Vazão outorgada (m³/h)",
    type: "precision2"
  }
];

export const headRowsRelatorioMassa = [
  {
    id: "id",
    label: ID
  },
  {
    id: "data_coleta",
    showChart: false,
    disablePadding: false,
    label: "Data Hora",
    type: "date_time",
  },
  {
    id: `${tensao}_l1_l2`,
    showChart: false,
    disablePadding: false,
    label: `${tensaoLabel} L1-L2`,
    type: "number",
  },
  {
    id: `${tensao}_l2_l3`,
    showChart: false,
    disablePadding: false,
    label: `${tensaoLabel} L2-L3`,
    type: "number",
  },
  {
    id: `${tensao}_l3_l1`,
    showChart: false,
    disablePadding: false,
    label: `${tensaoLabel} L3-L1`,
    type: "number",
  },
  {
    id: `${tensao}_l1_neutro`,
    showChart: false,
    disablePadding: false,
    label: `${tensaoLabel} L1-Neutro`,
    type: "number",
  },
  {
    id: `${tensao}_l2_neutro`,
    showChart: false,
    disablePadding: false,
    label: `${tensaoLabel} L2-Neutro`,
    type: "number",
  },
  {
    id: `${tensao}_l3_neutro`,
    showChart: false,
    disablePadding: false,
    label: `${tensaoLabel} L3-Neutro`,
    type: "number",
  },
  {
    id: `${pAtiva}_total`,
    showChart: false,
    disablePadding: false,
    label: `${pAtivaLabel} Total`,
    type: "number",
  },
  {
    id: `${pReativa}_total`,
    showChart: false,
    disablePadding: false,
    label: `${pReativaLabel} Total`,
    type: "number",
  },
  {
    id: `${pAparente}_total`,
    showChart: false,
    disablePadding: false,
    label: `${pAparenteLabel} Total`,
    type: "number",
  },
  {
    id: `${fPotencia}_total`,
    showChart: false,
    disablePadding: false,
    label: `${fPotenciaLabel} Total`,
    type: "number",
  },
  {
    id: `${corrente}_i1`,
    showChart: false,
    disablePadding: false,
    label: `Corr. I1`,
    type: "number",
  },
  {
    id: `${corrente}_i2`,
    showChart: false,
    disablePadding: false,
    label: `Corr. I2`,
    type: "number",
  },
  {
    id: `${corrente}_i3`,
    showChart: false,
    disablePadding: false,
    label: `Corr. I3`,
    type: "number",
  },
  {
    id: `${corrente}_n`,
    showChart: false,
    disablePadding: false,
    label: `Corr. N`,
    type: "number",
  },
];

export const headRowsRelatorioDados = [
  {
    id: "index_abs",
    label: "index"
  },
  {
    id: "data_coleta",
    showChart: false,
    disablePadding: false,
    label: "Data Coleta",
    type: "date_time",
  },
  {
    id: "createdAt",
    showChart: false,
    disablePadding: false,
    label: "Data (Inserção)",
    type: "date_time",
  },
  {
    id: "alarme",
    showChart: false,
    disablePadding: false,
    label: "Alarme",
    type: "text"
  },
  {
    id: "posto_reativo",
    showChart: false,
    disablePadding: false,
    label: "Posto Reativo",
    type: "text",
  },
  {
    id: "posto_horario",
    showChart: false,
    disablePadding: false,
    label: "Posto Horário",
    type: "text",
  },
  {
    id: "quadrante",
    showChart: false,
    disablePadding: false,
    label: "Quadrante",
    type: "number",
  },
  {
    id: "tarifa_reativos_ativada",
    showChart: false,
    disablePadding: false,
    label: "Tarifa Rea. Ativada",
    type: "number",
  },
  {
    id: "energia_consumida_kwh",
    showChart: false,
    disablePadding: false,
    label: "Consumida (KWH)",
    type: "number",
  },
  {
    id: "energia_consumida_kvarh",
    showChart: false,
    disablePadding: false,
    label: "Reativa (KVARH)",
    type: "number",
  },
  {
    id: "energia_consumida_kw_acc",
    showChart: false,
    disablePadding: false,
    label: "Consumida (ACC)",
    type: "number",
  },
  {
    id: "energia_consumida_kwar_acc",
    showChart: false,
    disablePadding: false,
    label: "Reativa (ACC)",
    type: "number",
  },
  {
    id: "modo_ssu",
    showChart: false,
    disablePadding: false,
    label: "Modo SSU",
    type: "text",
  },
  {
    id: "status_energia",
    showChart: false,
    disablePadding: false,
    label: "Status",
    type: "number",
  },
];

export const headRowsAguaRelatorioDados = [
  {
    id: "id",
    label: "ID"
  },
  {
    id: "data_coleta",
    showChart: false,
    disablePadding: false,
    label: "Data Coleta",
    type: "date_time",
  },
  {
    id: "createdAt",
    showChart: false,
    disablePadding: false,
    label: "Data (Inserção)",
    type: "date_time",
  },
  {
    id: "consumo",
    showChart: false,
    disablePadding: false,
    label: "Consumo m³",
  },
];

export const headRowsRelatorioQueda = [
  {
    id: "offMoment",
    label: "Horário queda",
    type: "date_time"
  },
  {
    id: "onMoment",
    showChart: false,
    disablePadding: false,
    label: "Horário retorno",
    type: "date_time",
  },
  {
    id: "duracaoString",
    showChart: false,
    disablePadding: false,
    label: "Tempo sem energia",
    duracaotype: "text"
  }
]

export const headRowsResumo = [
  { id: "gestor.id_equipamento", label: "Medidor", type: "text" },
  { id: "nome", label: "Descrição", type: "text" },
  { id: "data_inicial", label: "Dê", type: "text" },
  { id: "data_final", label: "Até", type: "text" },
];

export const headRowsEconomia = [
  { id: "date", label: "Data", type: "date" },
  {
    id: "kwh_economia",
    label: "Potencial de Economia (kWh)",
    type: "precision2",
  },
  { id: "tarifa_hfp", label: "Tarifa HFP (R$)", type: "precision2" },
  { id: "tarifa_hr", label: "Tarifa HR (R$)", type: "precision2" },
  {
    id: "potencial_economia",
    label: "Potencial de Economia (R$)",
    type: "precision2",
  },
];

export const headRowsValidador = [...allColetaHeadRows];
