import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Container } from "@material-ui/core";
import { filesService } from "../services/files_service";
import { colors } from "../constants/dc_constants";

const styles = () => ({
  container: {
    width: "100%",
    minWidth: 400,
    padding: 0,
  },
  tableWrapper: {
    border: "1px solid #949494",
    borderRadius: 5,
    width: "100%",
    padding: 10,
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    "& tr:first-child td span": {
      borderTop: 0,
    },
    "& td": {
      padding: 0,
    }
  },
  link: {
    color: colors.BLUE,
    textDecoration: "none",
  },
  linkWrapper: {
    width: "100%",
    display: "block",
    borderTop: "1px solid #C4C4C4",
    padding: 5,
    transition: "background-color 0.3s",
    "&:hover": {
      backgroundColor: "#F7F7F7",
    },
  },
});

class ShowGestorWater extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
    };
  };

  shortenFileName = (fileName) => {
    const maxLength = 40;
    if (fileName.length > maxLength) {
      const fileNameOnly = fileName.split(".")[0];
      const extension = fileName.split(".")[1];
      return `${fileNameOnly.slice(0, maxLength)}...${extension}`;
    }
    return fileName;
  };

  getFiles = (gestor_id, setFiles) => {
    filesService.listFiles(gestor_id, (response) => {
      if (response && response.data) {
        setFiles(response.data);
      }
    });
  };

  componentDidMount() {
    const gestor_id = this.props.data.id;
    this.getFiles(gestor_id, this.setFiles);
  };

  setFiles = (files) => {
    this.setState({ files });
  };

  render() {
    const { classes } = this.props;
    const gestor = this.props.data;
    const nomeCliente = gestor.usuario ? gestor.usuario.nome : "";
    return (
      <Container id="qr_container" className={classes.container}>
        <Typography>Fazenda: {gestor.fazenda}</Typography>
        <Typography>Cliente: {nomeCliente}</Typography>
        <Typography>Intermediante: {gestor.intermediante}</Typography>
        <Typography>Região: {gestor.regiao}</Typography>
        <Typography>Coordenadas: Lat: {gestor.lat} Long: {gestor.long}</Typography>
        <Typography>Local de medição: {gestor.nome_bombeamento}</Typography>
        <Typography>Id do Equipamento: {gestor.id_equipamento}</Typography>
        <Typography>MacID: {gestor.codigo_esn}</Typography>
        <Typography>Gateway: {gestor.gateway}</Typography>
        <Typography>Host gateway: {gestor.host_equip}</Typography>
        <Typography>Porta: {gestor.host_port}</Typography>
        <Typography>Tipo de equipamento: {gestor.equipamento}</Typography>
        {(gestor.equipamento === "horimetro") &&
          <>
            <Typography>Vazão: {gestor.vazao}</Typography>
            <Typography>Unidade Vazão: {gestor.unidade_vazao}</Typography>
          </>
        }
        {(gestor.equipamento === "hidrometro") &&
          <>
            <Typography>Vazão: {gestor.constante_pulso}</Typography>
            <Typography>Unidade Vazão: {gestor.unidade_cp}</Typography>
          </>
        }
        {this.state.files.length > 0 && (
          <>
            <h3 style={{textAlign: "center"}}>Documentos</h3>
            <div className={classes.tableWrapper}>
              <table className={classes.table}>
                <tbody>
                  {this.state.files.map((file, index) => (
                    <tr key={index}>
                      <td>
                        <a href={file.file_url} className={classes.link} download rel="noreferrer">
                          <span className={classes.linkWrapper}>
                            {this.shortenFileName(file.file_name)}
                          </span>
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
      </Container>
    );
  }
}

export default withStyles(styles)(ShowGestorWater);

