import { useEffect, useState } from "react";
import styles from "./filter.module.css"
import { makePeriods } from "../../util/utils";

function Filter({gestoresList, gestor, grouped, period, onFilter}) {
  const [gestorSelected, setGestorSelected] = useState(gestor);
  const [periods, setPeriods] = useState(makePeriods(gestorSelected.createdAt));
  const [periodSelected, setPeriodSelect] = useState(periods.find(item => item.date.isSame(period)));

  useEffect(() => {
    const periodsTemp = makePeriods(gestorSelected.createdAt)
    setPeriods(periodsTemp);
    if (!periodSelected || gestor !== gestorSelected) {
      setPeriodSelect(periodsTemp[periodsTemp.length - 1]);
    }
  }, [gestorSelected])

  const handleFilter = () =>{
    onFilter({gestorSelected, periodSelected})
  }

  return (
    <div className={styles.container}>
      <div className={styles.inputContainer}>
        <div className={styles.selectContainer}>
          Medidor:
          <select 
            value={gestorSelected.id} 
            onChange={(e) => setGestorSelected(gestoresList.find(item => item.id+'' === e.target.value))}
          >
            {gestoresList.map((item) =>{
              return <option value={item.id}>{item.fazenda}</option>
            })}
          </select>
        </div>
        <div className={styles.selectContainer}>
          Período:
          <select value = {periodSelected.id} 
          onChange={(e) => setPeriodSelect(periods.find(item => item.id == e.target.value))}>
            { periods.map(period => {
              return <option value={period.id}>{period.dateLabel}</option>
            })}
          </select>
        </div>
      </div>
      <div onClick={handleFilter} className={styles.buttonContainer}>
        <button className={styles.button}>Filtrar</button>
      </div>
    </div>
  )
}
export default Filter;