import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  FormControl,
  TextField,
  NativeSelect,
  Grid,
  InputLabel,
} from "@material-ui/core";
import { userService } from "../services/user_service";
import { validateShowResponseToast } from "../helpers/toast_alerts";
import { hostsABNT } from "../constants/headRows"
import DropZoneArea from "../components/DropZoneArea";

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  form: {
    minHeigth: 600,
    maxWidth: 775,
    minWidth: 775,
  },
  formControl: {
    minWidth: "220px",
  },
  container: {
    marginTop: 0,
  },
  labelTitle: {
    fontSize: 16,
    fontWeight: 500,
  }
});

class FormGestorWater extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: 0,
      usuario_id: "",
      consultor_id: "",
      id_equipamento: "",
      fazenda: "",
      lat: "",
      long: "",
      regiao: "",
      produto: "water",
      codigo_esn: "",
      connection: "",
      equipamento: "",
      gateway: "",
      unidade_vazao: "",
      constante_pulso: "",
      unidade_cp: "",
      usuarios: [],
      host_equip: hostsABNT[0],
      host_port: null,
      entrada_gpio: null,
      files: [],
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleFilesChange = (files) => {
    this.setState({ files });
  };

  getUsuarios = () => {
    userService.getUsuarios({}, (response) => {
      if (validateShowResponseToast(response, "", false)) {
        this.setState({
          usuarios: response.data,
        });
      }
    });
  };

  componentDidMount() {
    const data = this.props.data;
    if (data) {
      const checkHorarioPonta = data?.horario_ponta_inicio ? true : false
      this.setState({
        ...data,
        horario_ponta: checkHorarioPonta,
        host_equip: data.host_equip ?? hostsABNT[0],
      });
    }
    this.getUsuarios()
  }

  // Necessario em todos os form abertos em ActionFormDialog
  action() {
    this.props.handleFormState(this.state);
  }

  handleChange(e, field, int) {
    if (int == "int") {
      // Remove tudo o que não é número
      const newValue = e.target.value.replace(/[^\d]/g, '');
      this.setState({ [field]: newValue });
    } else {
      const { name, value } = e.target;
      if (name === 'lat' && (value > 90 || value < -90)) return; // limit latitude
      if (name == 'long' && (value > 180 || value < -180)) return; // limits longitude
      this.setState({ [name]: value });
    }
  }

  isShowShrink(value) {
    return value !== undefined && value !== "" && value !== null;
  }

  renderGatewayElements(gateway) {
    const { classes } = this.props;
    switch (gateway) {
      case "ABS":
        return (
          <>
            <Grid container className={classes.container} spacing={2}>
              <Grid item xs={4} className={classes.container}>
                <FormControl fullWidth>
                  <InputLabel shrink={true}>
                    <span className={classes.labelTitle}>Host</span>
                  </InputLabel>
                  <NativeSelect
                    fullWidth
                    label="Selecione o host"
                    value={this.state.host_equip}
                    name="host_equip"
                    onChange={this.handleChange}
                  >
                    {hostsABNT.map((host) => (
                      <option
                        key={host}
                        value={host}
                      >{`${host}`}</option>
                    ))}
                  </NativeSelect>
                </FormControl>
              </Grid>
              <Grid item xs={4} className={classes.container}>
                <TextField
                  label="Porta host"
                  name="host_port"
                  fullWidth
                  type="text"
                  value={this.state.host_port}
                  onChange={(e) => this.handleChange(e, "host_port", "int")}
                  required
                ></TextField>
              </Grid>
              <Grid item xs={4} className={classes.container}>
                <FormControl fullWidth>
                  <InputLabel shrink={true}>
                    <span className={classes.labelTitle}>Entrada GPIO</span>
                  </InputLabel>
                  <NativeSelect
                    fullWidth
                    label="Entrada GPIO"
                    value={this.state.entrada_gpio}
                    name="entrada_gpio"
                    onChange={this.handleChange}
                  >
                    <option value={''}
                      style={{ fontSize: "2px", visibility: "hidden" }}
                    ></option>
                    <option value={"1"}>1</option>
                    <option value={"2"}>2</option>
                    <option value={"3"}>3</option>
                    <option value={"4"}>4</option>
                    <option value={"5"}>5</option>
                    <option value={"6"}>6</option>
                    <option value={"7"}>7</option>
                    <option value={"8"}>8</option>
                  </NativeSelect>
                </FormControl>
              </Grid>
            </Grid>
          </>
        )
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <form
        className={classes.form}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <FormControl
          variant="outlined"
          fullWidth
          className={classes.formControl}
        >
          <Grid container className={classes.container} spacing={2}>
            <Grid item xs={12} className={classes.container}>
              Anexar documentos de Outorga e Laudo de Vazão
              <DropZoneArea onFilesChange={this.handleFilesChange} />
            </Grid>
          </Grid>
          <Grid container className={classes.container} spacing={2}>
            <Grid item xs={6} className={classes.container}>
              <TextField
                label="Nome da Fazenda"
                name="fazenda"
                fullWidth
                type="text"
                value={this.state.fazenda}
                onChange={this.handleChange}
              ></TextField>
            </Grid>
            <Grid item xs={6} className={classes.container}>
              <TextField
                label="Região"
                name="regiao"
                fullWidth
                type="text"
                value={this.state.regiao}
                onChange={this.handleChange}
              ></TextField>
            </Grid>
          </Grid>
          <Grid container className={classes.container} spacing={2}>
            <Grid item xs={6} className={classes.container}>
              <TextField
                label="Latitude"
                name="lat"
                fullWidth
                type="number"
                value={this.state.lat}
                onChange={this.handleChange}
              ></TextField>
            </Grid>
            <Grid item xs={6} className={classes.container}>
              <TextField
                label="Longitude"
                name="long"
                fullWidth
                type="number"
                value={this.state.long}
                onChange={this.handleChange}
              ></TextField>
            </Grid>
          </Grid>
          <Grid container className={classes.container} spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel shrink={true}>
                  <span className={classes.labelTitle}>Selecione o cliente</span>
                </InputLabel>
                <NativeSelect
                  fullWidth
                  label="Selecione o cliente"
                  value={this.state.usuario_id}
                  name="usuario_id"
                  onChange={this.handleChange}
                >
                  {[<option key="sUser" value=""></option>].concat(
                    this.state.usuarios.map((usuario) => {
                      if (usuario.permissao == 1 && usuario.produto != 0) {
                        return (
                          <option key={usuario.id} value={usuario.id}>
                            {usuario.id} - {usuario.nome}
                          </option>
                        );
                      };
                    })
                  )}
                </NativeSelect>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel shrink={true}>
                  <span className={classes.labelTitle}>Selecione o consultor</span>
                </InputLabel>
                <NativeSelect
                  fullWidth
                  label="Selecione o consultor"
                  value={this.state.consultor_id}
                  name="consultor_id"
                  onChange={this.handleChange}
                >
                  {[<option key="sUser" value=""></option>].concat(
                    this.state.usuarios.map((usuario) => {
                      if (usuario.permissao == 2) {
                        return (
                          <option key={usuario.id} value={usuario.id}>
                            {usuario.id} - {usuario.nome}
                          </option>
                        );
                      };
                    })
                  )}
                </NativeSelect>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container className={classes.container} spacing={2}>
            <Grid item xs={6} className={classes.container}>
              <TextField
                label="Intermediante"
                name="intermediante"
                fullWidth
                type="text"
                value={this.state.intermediante}
                onChange={this.handleChange}
              ></TextField>
            </Grid>
            <Grid item xs={6} className={classes.container}>
              <TextField
                label="Local de medição" //Este campo deve ser apresentado como Local de medição
                name="nome_bombeamento"
                fullWidth
                type="text"
                value={this.state.nome_bombeamento}
                onChange={this.handleChange}
              ></TextField>
            </Grid>
          </Grid>
          <Grid container className={classes.container} spacing={2}>
            <Grid item xs={6} className={classes.container}>
              <TextField
                label="Id do Equipamento"
                name="id_equipamento"
                fullWidth
                type="text"
                value={this.state.id_equipamento}
                onChange={this.handleChange}
              ></TextField>
            </Grid>
            <Grid item xs={6} className={classes.container}>
              <TextField
                label="MacID"
                name="codigo_esn"
                fullWidth
                type="text"
                value={this.state.codigo_esn}
                onChange={this.handleChange}
              ></TextField>
            </Grid>
          </Grid>
          <Grid container className={classes.container} spacing={2}>
            <Grid item xs={6} className={classes.container}>
              <FormControl fullWidth>
                <InputLabel shrink={true}>
                  <span className={classes.labelTitle}>Gateway</span>
                </InputLabel>
                <NativeSelect
                  fullWidth
                  label="Selecione o gateway"
                  value={this.state.gateway}
                  name="gateway"
                  onChange={this.handleChange}
                >
                  <option value={''}
                    style={{ fontSize: "2px", visibility: "hidden" }}
                  ></option>
                  <option value={"fantus"}>Fantus</option>
                  <option value={"onboard"}>Onboard</option>
                  <option value={"ABS"}>ABS</option>
                </NativeSelect>
              </FormControl>
            </Grid>
            <Grid item xs={6} className={classes.container}>
              <FormControl fullWidth>
                <InputLabel shrink={true}>
                  <span className={classes.labelTitle}>Metodo de conexão</span>
                </InputLabel>
                <NativeSelect
                  fullWidth
                  label="Selecione o metodo de conexão"
                  value={this.state.connection}
                  name="connection"
                  onChange={this.handleChange}
                  required
                >
                  <option value={''}
                    style={{ fontSize: "2px", visibility: "hidden" }}
                  ></option>
                  <option value={"ETHERNET"}>ETHERNET</option>
                  <option value={"CELULAR"}>CELULAR</option>
                </NativeSelect>
              </FormControl>
            </Grid>
          </Grid>
          {this.renderGatewayElements(this.state.gateway)}
          <Grid container className={classes.container} spacing={2}>
            <Grid item xs={4} className={classes.container}>
              <FormControl fullWidth>
                <InputLabel shrink={true}>
                  <span className={classes.labelTitle}>Tipo de medidor</span>
                </InputLabel>
                <NativeSelect
                  fullWidth
                  label="Selecione o tipo de equipamento"
                  value={this.state.equipamento}
                  name="equipamento"
                  onChange={this.handleChange}
                >
                  <option value={''}
                    style={{ fontSize: "2px", visibility: "hidden" }}
                  ></option>
                  <option value={"hidrometro"}>Pulsado</option>
                  <option value={"horimetro"}>Horimetro</option>
                </NativeSelect>
              </FormControl>
            </Grid>
            {this.state.equipamento === "horimetro" &&
              <>
                <Grid item xs={4} className={classes.container}>
                  <TextField
                    label="Vazão"
                    name="vazao"
                    fullWidth
                    type="number"
                    value={this.state.vazao}
                    onChange={this.handleChange}
                  ></TextField>
                </Grid>
                <Grid item xs={4} className={classes.container}>
                  <FormControl fullWidth>
                  <InputLabel shrink={true}>
                    <span className={classes.labelTitle}>Unidade Vazão</span>
                  </InputLabel>
                  <NativeSelect
                    fullWidth
                    label="Selecione a unidade de vazão"
                    value={this.state.unidade_vazao}
                    name="unidade_vazao"
                    onChange={this.handleChange}
                  >
                    <option value={''}
                      style={{ fontSize: "2px", visibility: "hidden" }}
                    ></option>
                    <option value={"l/s"}>l/s</option>
                    <option value={"m3/h"}>m3/h</option>
                  </NativeSelect>
                </FormControl>
                </Grid>
              </>
            }
            {this.state.equipamento === "hidrometro" &&
              <>
                <Grid item xs={4} className={classes.container}>
                  <TextField
                    label="Constante por pulso"
                    name="constante_pulso"
                    fullWidth
                    type="number"
                    value={this.state.constante_pulso}
                    onChange={this.handleChange}
                  ></TextField>
                </Grid>
                <Grid item xs={4} className={classes.container}>
                  <FormControl fullWidth>
                  <InputLabel shrink={true}>
                    <span className={classes.labelTitle}>Unidade Constante</span>
                  </InputLabel>
                  <NativeSelect
                    fullWidth
                    label="Unidade Constante"
                    value={this.state.unidade_cp}
                    name="unidade_cp"
                    onChange={this.handleChange}
                  >
                    <option value={''}
                      style={{ fontSize: "2px", visibility: "hidden" }}
                    ></option>
                    <option value={"l"}>l</option>
                    <option value={"m3"}>m3</option>
                  </NativeSelect>
                </FormControl>
                </Grid>
              </>
            }
          </Grid>
        </FormControl>
      </form>
    );
  }
}

export default withStyles(styles)(FormGestorWater);
