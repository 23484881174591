import React, { useState, useCallback } from "react";
import { Box, Button } from "@material-ui/core";
import { useDropzone } from "react-dropzone";
import { withStyles } from "@material-ui/core/styles";
import { DeleteForever } from "@material-ui/icons";

const styles = () => ({
  tableWrapper: {
    border: "1px solid #949494",
    borderRadius: 5,
    marginTop: 15,
    padding: 10,
    paddingBottom: 0,
  },
  filesTable: {
    width: "100%",
    borderCollapse: "collapse",
    fontSize: 12,
  },
  filesTableheader: {
    fontWeight: 500,
    "& th": {
      borderBottom: "1px solid #949494",
      textAlign: "left",
      position: "relative",
    },
  },
  filesTableBody: {
    "& td": {
      borderTop: "1px solid #C4C4C4",
      padding: 5,
    },
    "& tr:first-child td": {
      borderTop: 0,
    },
  },
  removeButton: {
    fontSize: "0.8rem",
    color: "red",
    border: "none",
    margin: 0,
    padding: 0,
    "&:hover": {
      border: 0,
    }
  },
});

const DropZoneArea = ({ classes, onFilesChange }) => {
  const [filesList, setFilesList] = useState([]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const newFiles = acceptedFiles.map((file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();

          reader.onload = () => {
            resolve({
              fieldname: "files",
              originalname: file.name,
              encoding: "7bit",
              mimetype: file.type,
              buffer: reader.result,
              size: file.size,
            });
          };

          reader.onerror = () => {
            reject(new Error("Erro ao processar o arquivo"));
          };

          reader.readAsArrayBuffer(file);
        });
      });

      Promise.all(newFiles)
        .then((processedFiles) => {
          const uniqueFiles = processedFiles.filter(
            (newFile) =>
              !filesList.some((file) => file.originalname === newFile.originalname)
          );

          const updatedFiles = [...filesList, ...uniqueFiles];
          setFilesList(updatedFiles);
          onFilesChange(updatedFiles);
        })
        .catch((error) => console.error(error.message));
    },
    [filesList, onFilesChange]
  );

  const removeFile = (fileName) => {
    const updatedFiles = filesList.filter((file) => file.originalname !== fileName);
    setFilesList(updatedFiles);
    onFilesChange(updatedFiles);
  };

  const fileRows = filesList.map(({ originalname, mimetype, size }) => (
    <tr key={originalname}>
      <td>{originalname}</td>
      <td>{mimetype}</td>
      <td>{size} bytes</td>
      <td>
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          className={classes.removeButton}
          onClick={() => removeFile(originalname)}
        >
          <DeleteForever/>
        </Button>
      </td>
    </tr>
  ));

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <>
      <Box
        {...getRootProps()}
        sx={{
          border: "1px dashed blue",
          p: 1,
          textAlign: "center",
          cursor: "pointer",
          transition: "background-color 0.3s",
          "&:hover": {
            borderColor: "primary.main",
            backgroundColor: "rgba(0, 0, 0, 0.03)",
          },
        }}
      >
        <input {...getInputProps()} />
        <p>Arraste e solte os arquivos aqui ou clique para selecionar.</p>
      </Box>
      {fileRows.length > 0 && (
        <div className={classes.tableWrapper}>
          <table className={classes.filesTable}>
            <thead>
              <tr className={classes.filesTableheader}>
                <th>Arquivo</th>
                <th>Tipo</th>
                <th>Tamanho</th>
                <th>Ação</th>
              </tr>
            </thead>
            <tbody className={classes.filesTableBody}>
              {fileRows}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default withStyles(styles)(DropZoneArea);
