import { getAxiosInstance } from "./service";

export const filesService = {
  createFile,
  listFiles,
}

async function createFile(data, files, handleResponse, handleAction) {
  let response = undefined;
  let errorResponse = undefined;

  const gestor_id = data.gestor_id;
  const formData = new FormData();

  formData.append("gestor_id", gestor_id);

  files.forEach((file) => {
    const blob = new Blob([new Uint8Array(file.buffer)], { type: file.mimetype });
    formData.append("files", blob, file.originalname);
  });

  try {
    response = await getAxiosInstance("/files").post("", formData);
  } catch (error) {
    errorResponse = error.response;
  }

  if (handleResponse) handleResponse(response || errorResponse);
  if (handleAction) handleAction();
}

async function listFiles(gestor_id, handleResponse) {
  let response = undefined;
  let errorResponse = undefined;

  try {
    response = await getAxiosInstance("/files").get("", {
      params: {
        gestor_id,
      },
    });
  } catch (error) {
    errorResponse = error.response;
  }

  if (handleResponse) handleResponse(response || errorResponse);
}

