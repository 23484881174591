import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { colors } from "../constants/dc_constants";
import { Typography } from "@material-ui/core";
import {
  OfflineBolt,
  People,
  Equalizer,
  Receipt,
  Map,
  EventAvailable,
  Notifications,
  ArrowDropDown,
  ArrowDropUp,
  Dashboard,
  Create,
  Assessment
} from "@material-ui/icons";
import browserHistory from "../helpers/browser_history";
import { userService } from "../services/user_service";
import { makeAlertDialog } from "../default/dialogs/DialogRefInterface";
import WaterDropIcon from "@mui/icons-material/WaterDrop";
import { ElectricalServices } from "@mui/icons-material";
import { RelatorioModal } from "./ModalRelatorio.jsx";

function switchRoute(route) {
  browserHistory.push(route);
}

function handleAgree() {
  userService.logout();
  browserHistory.push("/");
}

function handleLogout() {
  makeAlertDialog(handleAgree, "Atenção", "Deseja realmente sair da aplicação?").current.open();
}

export function MainListItems(props) {
  const [modalOpen, setModalOpen] = useState(false);
  let { browserHistory, itemClickHandle } = props;
  if (!itemClickHandle) itemClickHandle = () => { };

  const classes = useStyles();
  const [open, setOpen] = React.useState(true)
  const [isPowerOpen, setPowerIsOpen] = useState(false);
  const [isWaterOpen, setWaterIsOpen] = useState(false);
  const [openCadastro, setOpenCadastro] = React.useState(true);
  var user = JSON.parse(localStorage.getItem("user"));
  var permission = user["permissao"];
  var power = user["produto"] != 1;
  var water = user["produto"] != 0;

  const toggleSubMenuPower = () => {
    setPowerIsOpen(!isPowerOpen);
  };

  const toggleSubMenuWater = () => {
    setWaterIsOpen(!isWaterOpen);
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  }

  const handleCloseModal = () => {
    setModalOpen(false);
  }

  const menuItems = [
    {
      label: "Mapa",
      icon: <Map style={{ color: colors.DARK_GREY }} />,
      onClick: () => switchRoute("/dashboard"),
    },
    {
      label: "Power",
      icon: <OfflineBolt style={{ color: colors.ORANGE_POWER }} />,
      onClick: toggleSubMenuPower,
      isOpen: isPowerOpen,
      isVisible: power,
      subItems: [
        {
          label: "Medidores",
          icon: <OfflineBolt style={{ color: colors.ORANGE_POWER }} />,
          onClick: () => switchRoute("/dashboard/gestores"),
        },
        {
          label: "Concessionárias",
          icon: <ElectricalServices style={{color: colors.ORANGE_POWER}}/>,
          onClick: () => switchRoute("/dashboard/concessionarias"),
          isVisible: permission === 3
        },
        {
          label: "Fechamentos",
          icon: <EventAvailable style={{ color: colors.ORANGE_POWER }} />,
          onClick: () => switchRoute("/dashboard/fechamentos"),
        },
        {
          label: "Relatórios",
          icon: <Receipt style={{ color: colors.ORANGE_POWER }} />,
          onClick: () => switchRoute("/dashboard/relatorios"),
        },
        {
          label: "Gráficos",
          icon: <Equalizer style={{ color: colors.ORANGE_POWER }} />,
          onClick: () => switchRoute("/dashboard/charts"),
        },
        {
          label: "Alertas",
          icon: <Notifications style={{ color: colors.ORANGE_POWER }} />,
          onClick: () => switchRoute("/dashboard/alertas"),
          isVisible: permission === 3,
        },
        {
          label: "Cadastros",
          icon: <Create style={{ color: colors.ORANGE_POWER }} />,
          onClick: () => switchRoute("/dashboard/cadastros"),
          isVisible: permission === 3,
        }
      ],
    },

    {
      label: "Water",
      icon: <WaterDropIcon style={{ color: colors.BLUE }} />,
      onClick: toggleSubMenuWater,
      isOpen: isWaterOpen,
      isVisible: water,
      subItems: [
        {
          label: "Dashboard",
          icon: <Dashboard style={{ color: colors.BLUE }} />,
          onClick: () => switchRoute("/agua/dashboard"),
        },
        {
          label: "Medidores",
          icon: <WaterDropIcon style={{ color: colors.BLUE }} />,
          onClick: () => switchRoute("/agua/gestores"),
        },
        {
          label: "Relatório Gerencial",
          icon: <Receipt style={{ color: colors.BLUE }} />,
          onClick: () => switchRoute("/agua/relatorios"),
        },
        {
          label: "Relatório Fiscal",
          icon: <Assessment style={{ color: colors.BLUE }} />,
          onClick: handleOpenModal,
          isVisible: permission === 1,
        },
        {
          label: "Alertas",
          icon: <Notifications style={{ color: colors.BLUE }} />,
          onClick: () => switchRoute("/agua/alertas"),
          isVisible: permission === 3,
        },
      ],
    },
    {
      label: "Usuários",
      icon: <People style={{ color: colors.DARK_GREY }} />,
      onClick: () => switchRoute("/dashboard/usuarios"),
      isVisible: permission === 3,
    },
  ];

  return (
    <div>
      {menuItems.map((menuItem, index) => (
        (menuItem.isVisible || menuItem.isVisible == undefined) &&
        <div key={index}>
          <ListItem button onClick={menuItem.onClick}>
            <ListItemIcon>{menuItem.icon}</ListItemIcon>
            <Typography color="secondary">{menuItem.label}</Typography>
            {(menuItem.subItems && menuItem.subItems.length > 0) && (menuItem.isOpen ? (
              <ArrowDropUp style={{ color: "white" }} />
            ) : (
              <ArrowDropDown style={{ color: "white" }} />
            ))}
          </ListItem>
          {menuItem.isOpen && (
            <div style={{ marginLeft: "20px" }}>
              {menuItem.subItems.map((subItem, subIndex) => (
                (subItem.isVisible || subItem.isVisible == undefined) &&
                <ListItem key={subIndex} button onClick={subItem.onClick}>
                  <ListItemIcon>{subItem.icon}</ListItemIcon>
                  <Typography color="secondary">{subItem.label}</Typography>
                </ListItem>
              ))}
            </div>
          )}
        </div>
      ))}
      <RelatorioModal open={modalOpen} onClose={ handleCloseModal } />
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  }
}));
